.message {
    margin: 5px;
    border: 1px solid;

    &__title {
      font-size: large;
    }
  
    &__icon {
      height: 32px;
      background-color: transparent !important;
  
      &--small {
        height: 16px;
        margin: 2px;
      }
    }

    &__subtitle {
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #808080 !important;
        font-size: medium;

        &--collapsed {
          max-height: 3.6em;
        }
    }
  
    &__details {
      font-size: small;
      p {
        margin-bottom: unset;
        padding-left: 5px;
        label {
          width: 30%;
        }
        &:nth-child(2n) {
          background-color: #e0e0e0;
        }
      }
    }
  
    &__table {
      background-color: transparent;
    }
  
    &--info1 {
      border: 2px solid;
      border-color: #327c15;
    }
  
    &--error1 {
      border: 2px solid;
      border-color: #9e1b1b;
    }
  
    &--status-info {
      color: #327c15;
    }
  
    &--status-error {
      color: #9e1b1b;
    }
  
    &--status-inqueue {
      color: #cc9622;
    }
  
    &__spacer {
      flex: 1 1 auto;
    }
  }
  
  .table {
    font-size: 14px;
    &__header {
      text-align: center;
      font-weight: bold;
      background-color: #e0e0e0;
    }
  
    &__data {
      min-height: 25px;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        align-self: center;
      }
      button {
        background-color: transparent;
      }
      &:nth-child(2n) {
        background-color: #f0f0f0;
      }

      &--fileName {
        span {
          zoom: 0.8;
          padding: 0 !important;
          margin-right: 10px;
        }
      }
    }
  
    &__icon {
      height: 18px;
    }
  }
  