.app-header {
  background-color: white !important;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

.title {
  &-logo {
    max-height: 48px;
  }
}
