.logs {
    &__search {

    }

    &__results {
        height: 70vh;
        overflow: auto;
    }

    &__loader {
        height: 100%;
        zoom: 3;
    }
}