.bold {
  font-weight: 700;
}
@for $i from 0 through 100 {
  .fs-#{$i} {
    font-size: #{$i}px !important;
  }
  .mar-#{$i} {
    margin: #{$i}px !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }
  .pad-#{$i} {
    padding: #{$i}px !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }
  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }
  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }
}
.ml-a {
  margin-left: auto !important;
}
.mt-a {
  margin-top: auto !important;
}
.mb-a {
  margin-bottom: auto !important;
}
.mr-a {
  margin-right: auto !important;
}
.m-a {
  margin: auto !important;
}
.column-border-left {
  border-left: 2px solid #323838;
  h5 {
    padding: 12px 0;
    margin-bottom: 0;
    letter-spacing: 1.5px;
  }
  p {
    padding: 12px 0;
    margin-bottom: 0;
  }
}
.tt-upper {
  text-transform: uppercase;
}
.lt-1 {
  letter-spacing: 1.5px;
}

.lh-1-8 {
  line-height: 1.8em;
}

.lh-1-3 {
  line-height: 1.3;
}

.flex {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.center {
  align-items: center;
  justify-content: center;
}

.self-center {
  align-self: center;
}

.justify-right {
  margin-right: 0;
  margin-left: auto;
}

.hide {
  display: none !important;
}

.flex-box {
  display: -webkit-box;
}

.full-height {
  height: 90vh;
}

.full-width {
  max-width: none !important;
}

.align {
  display:flex;
  &--right {
    justify-content: flex-end;
  }
  &--left {
    justify-content: flex-start;
  }
  &--center {
    justify-content: center;
  }
}
